import React from "react"
import Container from "./Container"
import { useTranslation } from "react-i18next"

type Props = {}

const OpticalNetworkMap: React.ComponentType<Props> = () => {
  const { i18n } = useTranslation()
  const googleMapsLink = i18n.language === "cs" ? "https://www.google.com/maps/d/embed?mid=1mGNkCJhtyAJegCuptlgf6C0crWq3X28&ehbc=000000&z=7" : "https://www.google.com/maps/d/embed?mid=1pxDBq6hrbcXcmeclmsBc_0HS9nupvs8&ehbc=000000&z=7"

  return (
    <div className={"my-10"}>
      <Container>
        <div style={{ overflow: "hidden" }}>
          <div style={{ "background-color": "#f5f5f5", height: "10rem", "margin-bottom": "-16rem" }}></div>
          <iframe style={{
            width: "100%",
            height: "54rem",
            "margin-top": "2.5rem",
            "margin-bottom": "3.5rem",
            "clip-path": "polygon(0% 7.7%, 100% 7.7%, 100% 100%, 0% 100%)"
          }}
                  src={googleMapsLink}></iframe>
        </div>
      </Container>
    </div>
  )
}

export default OpticalNetworkMap
